import React from 'react';
import Img from "gatsby-image";
import styles from './sponsors.module.scss';
import { useSponsorsMetadata } from '@queries/use-sponsors-metadata';

const Sponsors = () => {
  const { sponsors, thumbnails, getFluidLogo } = useSponsorsMetadata();

  return (
    <div className={styles.container}>
      {
        sponsors.map(sponsor => {
          const fluidLogo = getFluidLogo(sponsor, thumbnails);

          return (
            <div className={styles.item} key={fluidLogo.srcSet}>
              <a href={sponsor.link}>
                <div className={styles.logoWrapper}>
                  <Img fluid={fluidLogo} title={sponsor.name} alt={sponsor.name} />
                </div>
              </a>
            </div>
          );
        })
      }
    </div>
  );
};

export default Sponsors;
