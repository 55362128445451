import { useStaticQuery, graphql } from 'gatsby';

const parseSponsorsResponse = sponsors => {
  // Since only 1 index.json file exists,
  // response includes only 1 node
  const [indexPageNode] = sponsors.nodes;
  if (indexPageNode && indexPageNode.sponsors) {
    return indexPageNode.sponsors;
  }

  return [];
};

const parseThumbnails = thumbnails => {
  if (thumbnails && thumbnails.edges) {
    return thumbnails.edges.map(edge => ({ ...edge.node }));
  }
  
  return [];
};

const getFluidThumbnail = (sponsor, thumbnails) => {
  if (thumbnails && thumbnails.length === 0) {
    return null;
  }

  if (sponsor && sponsor.thumbnail) {
    const image = thumbnails.find(thumbnail => thumbnail.name === sponsor.thumbnail);
    if (image) {
      return image.childImageSharp.fluid;
    } else {
      return null;
    }
  }

  return null;
};

export const useSponsorsMetadata = () => {
  const data = useStaticQuery(
    graphql`
      query SponsorsMetaData {
        thumbnails: allFile(filter: {sourceInstanceName: {eq: "sponsors-thumbnails"}}) {
          edges {
            node {
              name
              childImageSharp {
                fluid(quality: 60) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        sponsors: allSponsorsJson {
          nodes {
            sponsors {
              link
              name
              thumbnail
            }
          }
        }
      }
    `
  );

  return {
    getFluidLogo: getFluidThumbnail,
    thumbnails: parseThumbnails(data.thumbnails),
    sponsors: parseSponsorsResponse(data.sponsors),
  };
};